import COFFEEWEB_STORAGE_KEYS from './LocalStorage'
import REG_EX from './RegEx'
import ROUTE_STRINGS from './RouteStrings'
import coffeeReportJsonLinks from './coffeeReportJsonLinks'

const STATIC_ROUTE_STRINGS = {
  home: 'https://www.coffeeweb.com/',
  termsAndCondition: 'https://www.coffeeweb.com/TermsAndCondition.html',
  privacyPolicy: 'https://www.coffeeweb.com/PrivacyPolicy.html'
}

const IMAGE_SOURCE_URLS = {
  checkImage: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/img_check.png',
  cancelImage: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/img_cancel.png'
}

const WEATHER_API_URL = {
  apiUrl: 'https://api.weatherapi.com/v1/search.json'
}

const coffeewebLogo = {
  coloredLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_colored_logo.png',
  transparentLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_transparent_logo.png'
}

const COFFEEQUOTES_STATIC_VALUES = {
  websocketReconnectionInterval: 5 * 60 * 1000,
  socketHealthCheckInterval: 2000,
  handleWorkbookDropDown: [
    { value: 1, contractType: 'RW' },
    { value: 2, contractType: 'AW' },
    { value: 3, contractType: 'RD' },
    { value: 4, contractType: 'AD' }
  ],
  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  convertToValue: 22.0462
}

const socialMediaChannels = {
  facebookLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Facebook.png',
  instagramLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Instagram.png',
  whatsappLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/WhatApp.png',
  youtubeLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Youtube.jpg',
  twitterLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Twitter.png',
  linkedinLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Linked+In.png',
  quoraLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Quora.png',
  tumblrLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Tumblr.png',
  telegramLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Telegram.png',
  mediumLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Medium.png',
  redditLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Reddit.png',
  threadLogo: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Thread.png'
}

export { COFFEEWEB_STORAGE_KEYS, REG_EX, ROUTE_STRINGS, STATIC_ROUTE_STRINGS, IMAGE_SOURCE_URLS, WEATHER_API_URL, COFFEEQUOTES_STATIC_VALUES, coffeewebLogo, coffeeReportJsonLinks, socialMediaChannels }
