import './styles.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Toast } from 'primereact/toast'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'

const AccordionPopUp = ({ setLoading, mockCoffeePriceHistoryData, selectedQualityForHistoryData, setSelectedQualityForHistoryData, accordionPopUpTest }) => {
  const [countryId, countryName, cropYear, qualityName, forexType, flagUrl, remarks] = selectedQualityForHistoryData

  const { t } = useTranslation()
  const toast = useRef(null)

  const [coffeePriceHistoryData, setCoffeePriceHistoryData] = useState([])
  const [isDialogModalOpen, setIsDialogModalOpen] = useState(false)

  useEffect(() => {
    if (selectedQualityForHistoryData?.length) {
      fetchHistoryData()
      setIsDialogModalOpen(false)
    }
  }, [selectedQualityForHistoryData])

  useEffect(() => {
    if (accordionPopUpTest) {
      setIsDialogModalOpen(true)
      setCoffeePriceHistoryData(mockCoffeePriceHistoryData)
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = isDialogModalOpen ? 'hidden' : ''

    return () => {
      document.body.style.overflow = ''
    }
  }, [isDialogModalOpen])

  const fetchHistoryData = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getGlobalCoffeePriceHistoryData({ idCountry: countryId, countryName, cropYear, qualityName })

      if (response.data.returnLst?.length > 0) {
        setCoffeePriceHistoryData(response.data.returnLst[0].data)
        setIsDialogModalOpen(true)
      } else {
        showErrorToast('NO_DATA_FOUND')
        setCoffeePriceHistoryData([])
      }
    } catch (error) {
      showErrorToast('FAILED_TO_GET_DATA')
      setCoffeePriceHistoryData([])
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    setIsDialogModalOpen(false)
  }

  useEffect(() => {
    if (!isDialogModalOpen) {
      setSelectedQualityForHistoryData([])
    }
  }, [isDialogModalOpen])

  const showErrorToast = (message) => toast.current?.show({ severity: 'error', summary: 'Error', detail: t(message), life: 3000 })

  const priceColumnName = () => {
    if (!forexType) return ''

    const forexTypes = forexType.split(',').map((item) => item.replace(/INR\/|\/INR/g, '').trim())
    const result = [...new Set(forexTypes)]

    return `Prices in ${result} (${remarks})`
  }

  const priceForTheRemarks = () => {
    if (!remarks) return ''

    return `Prices in INR (${remarks})`
  }

  const getComparisonClass = (currentValue, prevValue) => (currentValue >= (prevValue || 0) ? 'value-high' : 'value-low')

  const globalRawCoffeesModalContent = () => (
    <div className="global-coffee-price-popup-modal" data-testid="global-raw-coffee-price-modal-popup">
      <div className="global-raw-coffee-price-preview-table-wrapper" data-testid="global-raw-coffee-price-table-wrapper">
        <table className="global-raw-coffee-price-preview-table" data-testid="global-raw-coffee-price-table-preview">
          <thead className="global-raw-coffee-price-preview-table-header" data-testid="global-raw-coffee-price-preview-header">
            <tr>
              <th colSpan={2} className="header-country" data-testid="crop-year-flag">
                {t('COUNTRY')} : <img src={flagUrl} alt="Flag" className="flag-icon" data-testid="flag-icon" /> {countryName}
              </th>
              <th colSpan={countryName === 'India' ? 3 : 5} className="header-previous-data" data-testid="previous-data-header">
                {t('PREVIOUS_DATA')}
              </th>
              <th colSpan={countryName === 'India' ? 4 : 5} className="header-quality" data-testid="quality-name">
                {qualityName}
              </th>
            </tr>
            <tr>
              <th rowSpan={2} className="accordion-date-tr">
                {t('DATE')}
              </th>
              <th rowSpan={2} data-testid="crop-year">
                {t('CROP_YEAR')}
              </th>
              <th rowSpan={2}>{t('TERMINAL')}</th>
              <th rowSpan={2}>{t('TERMINAL_LEVEL')}</th>
              {countryName === 'India' ? (
                <>
                  <th rowSpan={2} data-testid="forex-type">
                    {forexType}
                  </th>
                  <th colSpan={2} data-testid="price-remarks">
                    {priceForTheRemarks()}
                  </th>
                </>
              ) : (
                <>
                  <th colSpan={2} data-testid="price-column-non-indian">
                    {priceColumnName()}
                  </th>
                  <th rowSpan={2} data-testid="forex-type-non-indian">
                    {forexType}
                  </th>
                  <th colSpan={2} data-testid="price-remarks-non-india">
                    {priceForTheRemarks()}
                  </th>
                </>
              )}
            </tr>
            <tr>
              <th colSpan={2} className="between-header">
                {t('BETWEEN')}
              </th>
              {countryName !== 'India' && (
                <th colSpan={2} className="between-header">
                  {t('BETWEEN')}
                </th>
              )}
            </tr>
          </thead>

          {coffeePriceHistoryData.length > 0 ? (
            <tbody className="global-raw-coffee-price-preview-table-body" data-testid="global-raw-coffee-price-table-body">
              {coffeePriceHistoryData.length > 0 ? (
                coffeePriceHistoryData.map(({ uploadedDtm, cropYear, terminal, terminalLevel, rangeStart, rangeEnd, forexValue, convertedRangeStart, convertedRangeEnd }, index) => {
                  const prevData = coffeePriceHistoryData[index + 1] || {}

                  return (
                    <tr key={index}>
                      <td data-testid="uploadedDtm-value" className="date-td">
                        {uploadedDtm}
                      </td>
                      <td data-testid="cropYear-value">{cropYear}</td>
                      <td data-testid="terminal-value" className="terminal-data-value">
                        {terminal}
                      </td>
                      <td data-testid="terminal-level-value" className="terminal-data-value">
                        {terminalLevel}
                      </td>
                      {countryName === 'India' ? (
                        <>
                          <td data-testid="forex-value">{forexValue}</td>
                          <td data-testid="range-start-value" className={getComparisonClass(rangeStart, prevData.rangeStart)}>
                            {rangeStart}
                          </td>
                          <td data-testid="range-end-value" className={getComparisonClass(rangeEnd, prevData.rangeEnd)}>
                            {rangeEnd}
                          </td>
                        </>
                      ) : (
                        <>
                          <td data-testid="converted-range-start-value">{rangeStart}</td>
                          <td data-testid="converted-range-end-value">{rangeEnd}</td>
                          <td data-testid="forex-value">{forexValue}</td>

                          <td data-testid="range-start-value" className={convertedRangeStart >= (prevData.convertedRangeStart || 0) ? 'value-high' : 'value-low'}>
                            {convertedRangeStart}
                          </td>
                          <td data-testid="range-end-value" className={convertedRangeEnd >= (prevData.convertedRangeEnd || 0) ? 'value-high' : 'value-low'}>
                            {convertedRangeEnd}
                          </td>
                        </>
                      )}
                    </tr>
                  )
                })
              ) : (
                <tr></tr>
              )}
            </tbody>
          ) : (
            <tbody className="global-raw-coffee-price-preview-table-body">
              <tr>
                <td className="no-data-found-td" colSpan={7}>
                  {t('NO_DATA_FOUND')}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  )

  return (
    <div className="global-raw-coffee-price-accordian-popup" data-testid="global-raw-coffee-price-accordion-popup">
      <DialogUIModal titlePosition="center" size="lg" showModal={isDialogModalOpen} bodyContent={globalRawCoffeesModalContent} handleClose={() => handleCloseModal()} testId="accordion-popup" />
      {coffeePriceHistoryData.length === 0 && <Toast ref={toast} />}
    </div>
  )
}

export default AccordionPopUp
