import './styles.scss'
import React from 'react'
import { AppLoader } from 'Assets/Icons'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { useTranslation } from 'react-i18next'

const LayoutContainer = ({ children, isLoading, isCustomLoading }) => {
  const pageUrl = window.location.pathname
  const { t } = useTranslation()

  const extractURL = (url) => {
    const splitPageUrl = url.split('/')
    const currentUrl = splitPageUrl.pop()

    return currentUrl
  }

  const isMenuAbsent = extractURL(pageUrl) === extractURL(ROUTE_STRINGS.login) || extractURL(pageUrl) === extractURL(ROUTE_STRINGS.createAccount)

  return (
    <div className={`layout-body-content ${isMenuAbsent ? 'menu-absent' : ''}`} id="layout-container-div" data-testid="layout-container">
      {isLoading && (
        <div className="layout-loader-wrapper" data-testid="loader-wrapper">
          <AppLoader className="layout-loader" data-testid="loader" />
        </div>
      )}
      {isCustomLoading && (
        <div className="layout-loader-wrapper">
          <AppLoader className="layout-loader" />
          <div className="custom-loader-text-message">{t(isCustomLoading)}</div>
        </div>
      )}

      {children}
    </div>
  )
}

export default LayoutContainer
