import './styles.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { FloatLabel } from 'primereact/floatlabel'
import { Checkbox } from 'primereact/checkbox'
import { SupportNewSvg } from 'Assets/Images'
import { coffeewebLogo, STATIC_ROUTE_STRINGS } from 'Utils/Constants'
import { getLocalUserDetails, getLocalLoggedInStatus } from 'Utils/LocalStorage_Handler'
import Validation from 'Utils/Validation'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import Button from 'Components/UIComponent/Button'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import MetaTagsComponent from 'Components/MetaTagsComponent'
import Package from '../../../package.json'

const SupportRequest = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { setLoading } = useContext(CoffeeWebContext)

  const userDetails = getLocalUserDetails()
  const isLoggedIn = getLocalLoggedInStatus()

  const [allError, setAllError] = useState(false)
  const [termsAndCondition, setTermsAndCondition] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [inputValues, setInputValue] = useState({
    name: '',
    email: isLoggedIn ? userDetails?.email : '',
    subject: '',
    phone: '',
    message: ''
  })

  const [errorState, setErrorState] = useState({
    name: false,
    email: false,
    phone: false,
    subject: false,
    message: false
  })

  useEffect(() => {
    setLoading(false)
  }, [])

  const supportRequestPostData = async () => {
    setLoading(true)

    const apiEndPoint = isLoggedIn ? apiAdapterCoffeeWeb.sendSupportEmail : apiAdapterCoffeeWeb.requestForSupport

    const { subject, message } = inputValues
    const commonData = { subject, message }

    let additionalData

    if (isLoggedIn) {
      const { id: userId, isEmailVerified: mode, phone } = userDetails
      const { email } = inputValues

      additionalData = { userId, email, mode, phone }
    } else {
      const { name, phone, email: emailId } = inputValues

      additionalData = { name, phone, emailId }
    }

    const data = { ...commonData, ...additionalData }

    try {
      const value = await apiEndPoint(data)

      setAllError(false)

      setFormSubmitted(true)
      if (!isLoggedIn && value) {
        setInputValue({
          name: '',
          email: '',
          subject: '',
          phone: '',
          message: ''
        })
        setFormSubmitted(true)
        setTermsAndCondition(false)
      }
    } catch {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'name') {
      setInputValue({ ...inputValues, name: value })
      setErrorState((prevState) => ({ ...prevState, name: !Validation.nameValidation(value) }))
    }
    if (name === 'email') {
      setInputValue({ ...inputValues, email: value })
      setErrorState((prevState) => ({ ...prevState, email: !Validation.emailValidation(value) }))
    }
    if (name === 'phone' && value.length <= 15) {
      const isValidPhoneNumber = Validation.phoneNumberValidation(value)
      const isInvalidLength = value.length < 6

      setInputValue({ ...inputValues, phone: value })
      setErrorState((prevState) => ({ ...prevState, phone: !isValidPhoneNumber || isInvalidLength }))
    }
    if (name === 'subject') {
      setInputValue({ ...inputValues, subject: value })
      setErrorState((prevState) => ({ ...prevState, subject: true }))
    }
    if (name === 'message') {
      setInputValue({ ...inputValues, message: value })
      setErrorState((prevState) => ({ ...prevState, message: true }))
    }
  }

  const handleBlur = (e) => {
    const { name, value } = e.target

    if (name === 'name') {
      setInputValue({ ...inputValues, name: value })
      setErrorState((prevState) => ({ ...prevState, name: !Validation.nameValidation(value) }))
    }
    if (name === 'email') {
      setInputValue({ ...inputValues, email: value })
      setErrorState((prevState) => ({ ...prevState, email: !Validation.emailValidation(value) }))
    }
    if (name === 'phone') {
      const isValidPhoneNumber = Validation.phoneNumberValidation(value)
      const isInvalidLength = value.length < 6

      setInputValue({ ...inputValues, phone: value })
      setErrorState((prevState) => ({ ...prevState, phone: !isValidPhoneNumber || isInvalidLength }))
    }
    if (name === 'subject') {
      setInputValue({ ...inputValues, subject: value })
      setErrorState((prevState) => ({ ...prevState, subject: true }))
    }
    if (name === 'message') {
      setInputValue({ ...inputValues, message: value })
      setErrorState((prevState) => ({ ...prevState, message: true }))
    }
  }

  const handleSubmit = () => {
    const { name, email, phone, subject, message } = inputValues

    const isNameInvalid = !Validation.nameValidation(name)
    const isEmailInvalid = !Validation.emailValidation(email)
    const isPhoneInvalid = !Validation.phoneNumberValidation(phone) || phone.length < 6
    const isSubjectShort = subject.trim().length < 10
    const isMessageShort = message.trim().length < 100

    setErrorState({
      name: !name || isNameInvalid,
      email: !email || isEmailInvalid,
      phone: !phone || isPhoneInvalid,
      subject: !subject || isSubjectShort,
      message: !message || isMessageShort
    })

    const isFormInvalid = isLoggedIn ? !email || isEmailInvalid || isSubjectShort || isMessageShort : !name || isNameInvalid || !email || isEmailInvalid || !phone || isPhoneInvalid || !subject || isSubjectShort || !message || isMessageShort || !termsAndCondition

    if (isFormInvalid) {
      setAllError(true)

      return
    }

    supportRequestPostData()
  }

  const handleBackButtonClicked = () => {
    history.goBack()
  }

  const handleTicketButtonClicked = () => {
    if (isLoggedIn) {
      setInputValue({
        ...inputValues,
        subject: '',
        message: ''
      })
    }
    setFormSubmitted(false)
    setTermsAndCondition(false)
  }

  return (
    <>
      <div className="supportRequest-page">
        {!userDetails && <MetaTagsComponent title={t('SUPPORT_REQUEST_TITLE')} description={t('SUPPORT_REQUEST_DESCRIPTION')} keywords={t('SUPPORT_REQUEST_KEYWORDS')} url="https://coffeeweb.com/support" h1Content={t('SUPPORT_REQUEST_H1_CONTENT')} />}
        <div className="supportRequest">
          <div className="support-header">
            <div className="support-title">{t('SUPPORT')}</div>
          </div>
          <div className={`support-main-container ${!isLoggedIn ? 'support-main' : ''}`}>
            <div className={`support-image-content ${!isLoggedIn ? `support-image ${formSubmitted ? 'form-submit' : ''}` : 'support-image-logged-in'}`}>
              <div className={`support-image-container ${isLoggedIn ? 'support-image-logo' : ''}`}>
                <div className="support-image-svg">
                  <SupportNewSvg className="support-svg" data-testid="support-svg" aria-label="supportSvg" />
                </div>
                {!isLoggedIn && (
                  <div className="support-logo-content">
                    <img src={coffeewebLogo.coloredLogo} alt="coffeeweb-logo" className="coffeeweb-logo" data-testid="coffee-web-logo" aria-label="coffeeWebLogo" />
                  </div>
                )}
              </div>
            </div>
            <div className="support-form-container">
              {!formSubmitted ? (
                <>
                  {!isLoggedIn && (
                    <>
                      <FloatLabel>
                        <InputText name="name" value={inputValues.name} variant="outlined" onChange={(e) => handleChange(e)} onBlur={(e) => handleBlur(e)} className="input-text" data-testid="name-input-field" />
                        <label htmlFor="name" className="label-style">
                          {t('ENTER_YOUR_NAME')}
                          <span className="required-field" data-testid="mandatory-field">
                            *
                          </span>
                        </label>
                      </FloatLabel>
                      {!inputValues.name && errorState.name && <div className="error-message">{t('NAME_IS_REQUIRED')}</div>}
                      {inputValues.name && errorState.name && <div className="error-message">{t('ENTER_ONLY_LETTERS')}</div>}
                    </>
                  )}
                  <FloatLabel>
                    <InputText value={inputValues?.email || ''} disabled={!!(isLoggedIn && userDetails?.email)} name="email" variant="outlined" onChange={(e) => handleChange(e)} onBlur={(e) => handleBlur(e)} className="input-text" data-testid="email-input-field" />
                    <label htmlFor="name" className="label-style">
                      {!isLoggedIn ? t('ENTER_YOUR_EMAIL') : t('EMAIL')}{' '}
                      <span className="required-field" data-testid="mandatory-field">
                        *
                      </span>
                    </label>
                  </FloatLabel>

                  {!inputValues.email && errorState.email && <div className="error-message">{t('EMAIL_IS_REQUIRED')}</div>}
                  {inputValues.email && errorState.email && <div className="error-message">{t('INVALID_EMAIL_FORMAT')}</div>}

                  {!isLoggedIn && (
                    <>
                      <FloatLabel>
                        <InputText name="phone" type="number" value={inputValues?.phone} variant="outlined" onChange={(e) => handleChange(e)} onBlur={(e) => handleBlur(e)} className="input-text" data-testid="phone-input-field" />
                        <label htmlFor="name" className="label-style">
                          {t('ENTER_YOUR_PHONE')}{' '}
                          <span className="required-field" data-testid="mandatory-field">
                            *
                          </span>
                        </label>
                      </FloatLabel>
                      {!inputValues.phone && errorState.phone && <div className="error-message">{t('PHONE_IS_REQUIRED')}</div>}
                      {errorState.phone && inputValues.phone && inputValues.phone.length < 6 && <div className="error-message">{t('INVALID_PHONE_NUMBER')}</div>}
                    </>
                  )}
                  <FloatLabel>
                    <InputTextarea value={inputValues.subject} name="subject" variant="outlined" rows={3} onChange={(e) => handleChange(e)} onBlur={(e) => handleBlur(e)} className="input-text" data-testid="subject-input-field" />
                    <label htmlFor="name" className="label-style" data-testid="mandatory-field">
                      {t('SUBJECT')} <span className="required-field">*</span>
                    </label>
                  </FloatLabel>
                  {!inputValues.subject && errorState.subject && <div className="error-for-subject">{t('SUBJECT_IS_REQUIRED')}</div>}
                  {errorState.subject && inputValues.subject && inputValues.subject.length < 10 && <div className="error-for-subject">{t('SUBJECT_ERROR_STATE')}</div>}
                  <FloatLabel className="message-float-label">
                    <InputTextarea rows={4} value={inputValues.message} name="message" onChange={(e) => handleChange(e)} onBlur={(e) => handleBlur(e)} className="input-text" data-testid="message-input-field" />
                    <label htmlFor="name" className="label-style" data-testid="mandatory-field">
                      {t('MESSAGE')} <span className="required-field">*</span>
                    </label>
                  </FloatLabel>
                  {!inputValues.message && errorState.message && <div className="error-for-subject">{t('MESSAGE_IS_REQUIRED')}</div>}
                  {errorState.message && inputValues.message && inputValues.message.length < 100 && <div className="error-for-subject">{t('MESSAGE_ERROR_STATE')}</div>}

                  {!isLoggedIn && (
                    <>
                      <div className="support-terms-and-conditions-container">
                        <Checkbox onChange={(e) => setTermsAndCondition(e.target.checked)} checked={termsAndCondition} id="terms and condition" className="terms-and-condition support-checkbox" />
                        <div className={allError && !termsAndCondition ? 'terms-and-condition-content' : ''}>
                          <span className={allError && !termsAndCondition ? 'checkbox-error' : ''}> {t('I_AGREE_TO_THE')}</span>
                          <a className={allError && !termsAndCondition ? 'checkbox-error' : ''} href={STATIC_ROUTE_STRINGS.termsAndCondition} target="_blank" rel="noreferrer" data-testid="terms-and-conditions-text">
                            {t('TERMS_CONDITIONS')}
                          </a>
                          <span className={allError && !termsAndCondition ? 'checkbox-error' : ''}>{t('AND')}</span>
                          <a className={allError && !termsAndCondition ? 'checkbox-error' : ''} href={STATIC_ROUTE_STRINGS.privacyPolicy} target="_blank" rel="noreferrer" data-testid="privacy-and-policy-text">
                            <span className={allError && !termsAndCondition ? 'checkbox-error' : ''}>{t('PRIVACY_POLICY')}</span>
                          </a>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="support-submit-button">
                    <Button buttonType="SUBMIT" onButtonClick={handleSubmit} buttonWidth="full" testingId="submit-button-id" />
                  </div>
                </>
              ) : (
                <div className="support-success-message">
                  <div className="icon-container">
                    <i className="pi pi-check-circle check-circle-icon" />
                  </div>
                  <div className="support-success-header">
                    <h2>{t('SUPPORT_TICKET_RAISED_SUCCESSFULLY')}</h2>
                  </div>
                  <div className="support-content">
                    <p>{t('A_CONFIRMATION_MAIL_IS_ON_THE_WAY')}</p>
                    <p>{t('SUPPORT_REQUEST_MESSAGE')}</p>
                  </div>
                  <div className="support-success-button">
                    <Button buttonType="CUSTOM" buttonLabel={t('RAISED_A_NEW_TICKET')} onButtonClick={handleTicketButtonClicked} testingId="raised-ticket-button" />
                  </div>
                </div>
              )}
            </div>
          </div>
          {isLoggedIn && (
            <div className="go-back-button-container">
              <Button buttonType={'CUSTOM'} buttonLabel={t('GO_BACK')} variant={'soft-action'} buttonIcon={<i className="pi pi-arrow-left go-back-arrow-icon" />} onButtonClick={handleBackButtonClicked} testingId="go-back-button" />
            </div>
          )}
        </div>

        <div className={userDetails ? 'loggedIn-version-number-style' : 'version-number'} data-testid="package-version">
          {Package.version}
        </div>
      </div>
    </>
  )
}

export default SupportRequest
