import './styles.scss'
import React from 'react'
import { Galleria } from 'primereact/galleria'

const CarouselMedia = ({ mediaList, handleImagePreview }) => {
  const itemTemplate = ({ pathOfMedia, alt, typeMedia, idNewsFeed }) => <>{typeMedia === 'image' ? <img src={pathOfMedia} alt={alt} style={{ width: '100%' }} onClick={() => handleSelectMedia(idNewsFeed)} /> : <video controls src={pathOfMedia} alt={alt} style={{ width: '100%' }} onClick={() => handleSelectMedia(idNewsFeed)} />}</>

  const handleSelectMedia = (idNewsFeed) => {
    const imageIndex = mediaList.findIndex((ele) => ele.idNewsFeed === idNewsFeed)

    handleImagePreview(imageIndex)
  }

  return (
    <div className="caroselMedia_component" data-testid="carousel-media-component">
      {mediaList?.length > 0 && <Galleria value={mediaList} numVisible={5} circular style={{ maxWidth: '640px' }} showItemNavigators={mediaList.length > 1} showIndicatorsOnItem={true} showItemNavigatorsOnHover showIndicators={mediaList.length > 1} showThumbnails={false} item={itemTemplate} />}
    </div>
  )
}

export default CarouselMedia
