import './styles.scss'
import React, { useEffect, useState, useContext } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Badge } from 'primereact/badge'
import { OverlayPanel } from 'primereact/overlaypanel'
import { doc, onSnapshot } from 'firebase/firestore'
import { getLocalLatestNewsReadNotification, getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { db } from 'firebase.js'
import ErrorMessageModal from 'Components/UIComponent/ConfirmationModal'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'

const NotificationListenService = ({ showConfirmation, overlayPanelRef, mockNotificationCount, mockUserNotificationForUserData }) => {
  const userDetails = getLocalUserDetails()
  const params = new URLSearchParams()

  const history = useHistory()
  const { t } = useTranslation()
  const { isLoading, setLoading } = useContext(CoffeeWebContext)

  const onlyPageSize = 8
  const [userNotificationForUser, setUserNotificationForUser] = useState([])
  const [onlyPageNumber, setOnlyPageNumber] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [showTostify, setShowTostify] = useState(false)
  const [firebaseLatestNewsId, setFirebaseLatestNewsId] = useState(null)
  const [notificationCount, setNotificationCount] = useState(0)
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const [isIconClicked, setIsIconClicked] = useState(false)

  const [notificationMessageDetail, setNotificationMessageDetail] = useState({
    message: '',
    notificationId: ''
  })

  useEffect(() => {
    const cleanUp = init() || (() => {})

    return () => {
      cleanUp()
    }
  }, [])

  const init = () => {
    getUsersNotificationForUser(onlyPageNumber, onlyPageSize)
    let mounted = false

    if (userDetails?.phone || userDetails?.email) {
      const firebaseNewsId = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', 'NewsFeeds'), (doc) => {
        const firebaseResponse = doc.data()

        const subscriptionList = firebaseResponse?.LatestNewsForSubscription?.split(',')?.map((number) => parseInt(number.trim(), 10))
        const countryList = firebaseResponse?.LatestNewsForCountries?.split(',')?.map((number) => parseInt(number.trim(), 10))

        if (mounted) {
          if (subscriptionList.includes(userDetails?.sub?.subType) && countryList.includes(userDetails?.countryId) && !firebaseResponse?.IsDeleted && firebaseResponse?.LatestNewsLanguage === userDetails?.languageId && firebaseResponse?.SendNotification) {
            setFirebaseLatestNewsId(firebaseResponse?.LatestNewsId)
          }
        } else {
          mounted = true
        }
      })

      const firebaseNotificationCount = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', userDetails?.googlesignid ? userDetails?.email : userDetails?.phone), (doc) => {
        const firebaseResponse = doc.data()

        if (firebaseResponse?.UserNotifications?.UnreadCount >= 0) {
          setNotificationCount(firebaseResponse?.UserNotifications?.UnreadCount)
          setUnreadNotificationCount(firebaseResponse?.UserNotifications?.UnreadCount)
        }
      })

      return () => {
        firebaseNewsId()
        firebaseNotificationCount()
      }
    }
  }

  useEffect(() => {
    const latestNewsReadNotification = getLocalLatestNewsReadNotification()

    const selectedNewsId = typeof latestNewsReadNotification === 'number' ? [latestNewsReadNotification] : latestNewsReadNotification

    const newArray = userNotificationForUser?.map((ele) => {
      if (selectedNewsId?.includes(ele.sourceId)) {
        return { ...ele, isRead: true }
      }

      return ele
    })

    setUserNotificationForUser(newArray)

    if (notificationCount === 0) {
      setUserNotificationForUser(userNotificationForUser?.map((ele) => ({ ...ele, isRead: true })))
    }
  }, [notificationCount])

  useEffect(() => {
    if (firebaseLatestNewsId) {
      getNewsAndMedia()
    }
  }, [firebaseLatestNewsId])

  useEffect(() => {
    const body = document.querySelector('body')

    body.style.overflow = isIconClicked ? 'hidden' : 'auto'
  }, [isIconClicked])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (mockNotificationCount) {
      setNotificationCount(mockNotificationCount)
      setUnreadNotificationCount(mockNotificationCount)
      setShowTostify(mockNotificationCount)
      setUserNotificationForUser(mockUserNotificationForUserData)
      getNewsAndMedia()
    }
  }, [])

  const getUsersNotificationForUser = async (pageNumber, pageSize) => {
    try {
      const response = await apiAdapterCoffeeWeb.getUsersNotificationForUser({ userId: userDetails.id, pageNumber, pageSize })

      if (response.data?.returnLst?.appNotificationDTO.length > 0) {
        setUserNotificationForUser((userNotificationForUser) => [...userNotificationForUser, ...response.data.returnLst.appNotificationDTO])
        setOnlyPageNumber(onlyPageNumber + 1)

        const unReadNotifications = response.data.returnLst.notificationCount.unreadNotification

        setUnreadNotificationCount(unReadNotifications)
      } else {
        setHasMore(false)
      }
    } catch (error) {
      setHasMore(false)
    }
  }

  const getNewsAndMedia = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getNewsAndMediaById(firebaseLatestNewsId)
      const newObject = {
        isRead: false,
        sourceId: firebaseLatestNewsId,
        notificationMessage: response.data?.returnLst[0]?.subject,
        timeOnly: 'Just Now'
      }

      let found = false

      for (let i = 0; i < userNotificationForUser?.length; i++) {
        if (userNotificationForUser[i].sourceId === firebaseLatestNewsId) {
          found = true
        }
      }

      if (!found) {
        setUserNotificationForUser([newObject, ...userNotificationForUser])

        setShowTostify(true)

        setTimeout(() => {
          setShowTostify(false)
        }, 4000)
      }

      setNotificationMessageDetail({
        ...notificationMessageDetail,
        message: response.data?.returnLst[0]?.subject,
        notificationId: firebaseLatestNewsId
      })
    } catch (error) {
      setHasMore(false)
    }
  }

  const markNewsAsRead = async () => {
    setLoading(true)
    const updateData = {
      userId: userDetails.id,
      newsId: []
    }

    try {
      const { data } = await apiAdapterCoffeeWeb.updateNotificationReadAll(updateData)

      if (data) {
        const updatedNews = userNotificationForUser.map((ele) => ({
          ...ele,
          isRead: true
        }))

        setUserNotificationForUser(updatedNews)
        setUnreadNotificationCount(0)
      }
    } catch (error) {
      // console.log(error.response)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenNotification = (item) => {
    const { isRead, sourceId, webSourceLink, notificationId } = item

    if (isRead === false) {
      if (notificationCount >= 0) {
        setNotificationCount(notificationCount - 1)
      }
    }
    const updatedCards = userNotificationForUser.map((ele) => {
      if (ele.sourceId === sourceId) {
        return {
          ...ele,
          isRead: true
        }
      }

      return ele
    })

    setUserNotificationForUser(updatedCards)
    overlayPanelRef.current.hide()

    if (webSourceLink === 'subscription') {
      subscriptionNotificationMarkAsRead({ notificationId, isRead: true })
    } else {
      params.set('newsId', sourceId)

      history.push({
        search: params.toString(),
        state: { pageRef: 'yes' },
        pathname: ROUTE_STRINGS.opennewsnotification
      })
    }
  }

  const subscriptionNotificationMarkAsRead = async (payload) => {
    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.markAsReadSubscription(payload)

      if (data?.statusCode === 201) {
        history.push(ROUTE_STRINGS.subscription)
      }
    } catch (error) {
      // console.log(error.response)
    } finally {
      setLoading(false)
    }
  }

  const handleClick = (event) => {
    overlayPanelRef.current.toggle(event)
    setIsIconClicked(true)
  }

  const openConfirmationModal = () => {
    showConfirmation({
      title: t('ARE_YOU_SURE'),
      message: t('CONFORMATION_NOTIFICATION'),
      buttonLabel: t('YES'),
      dismissButtonLabel: t('NO'),
      onConfirm: () => {
        markNewsAsRead()
      }
    })
  }

  return (
    <div data-testid="notification-dropdown">
      <div>
        <div>
          {showTostify && (
            <div className="toast_div" data-testid="toast-container">
              <div className="toast_sub_div" data-testid="toast-sub-container">
                <div className="toast_container" data-testid="toast-content">
                  {ReactHtmlParser(notificationMessageDetail?.message)}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="notification_icon_box" data-testid="notification-icon-box">
          <i className="pi pi-bell p-overlay-badge notification-icon" onClick={handleClick} data-testid="notification-icon">
            {notificationCount !== 0 && <Badge value={notificationCount} className="notification_badge" severity="danger" data-testid="notification-badge"></Badge>}
          </i>
        </div>

        <OverlayPanel
          ref={overlayPanelRef}
          id="overlay-panel-id"
          onHide={() => {
            setIsIconClicked(false)
          }}
          className={`overlay-panel-content ${isLoading ? 'is-loading' : ''}`}
        >
          <div>
            <div className="popup_card_for_notification" id="scrollableDiv" data-testid="card-for-notification-container">
              <div className="notification-sub-div">
                <div>
                  <div className="notification-text">{t('NOTIFICATIONS')}</div>
                </div>
                <div>
                  {unreadNotificationCount > 0 && (
                    <div className="notification-mark-read" onClick={openConfirmationModal}>
                      {t('MARK_ALL_AS_READ')}
                    </div>
                  )}
                </div>
              </div>
              {userNotificationForUser.length && (
                <div className="see-more-notification">
                  <div
                    className="see-all-notification-text"
                    onClick={() => {
                      history.push(ROUTE_STRINGS.notification)
                      overlayPanelRef.current.hide()
                    }}
                  >
                    {t('SEE_ALL_NOTIFICATIONS')}
                  </div>
                </div>
              )}

              <InfiniteScroll dataLength={userNotificationForUser.length} next={() => getUsersNotificationForUser(onlyPageNumber, onlyPageSize)} hasMore={hasMore} scrollableTarget="scrollableDiv" loader={<div className="infinite_scroll_div">Loading...</div>} className="infinite_scroll">
                {userNotificationForUser &&
                  userNotificationForUser?.map((ele, index) => (
                    <div key={index} className="notification-menu" onClick={() => handleOpenNotification(ele)} id={ele.isRead === false && 'unRead'} data-testid="notification-menu-container">
                      <div className="notification-list" data-testid="notification-list-container">
                        <div>
                          <div className="notification-html-news-list" data-testid="notification-html-news-list-container">
                            {ReactHtmlParser(ele.notificationMessage)}
                          </div>
                        </div>
                        <div className="notification-news-date" data-testid="notification-news-date-container">
                          {ele.timeOnly}
                        </div>
                      </div>
                    </div>
                  ))}
              </InfiniteScroll>
              {userNotificationForUser?.length === 0 && <div className="no-notification">{t('NO_NOTIFICATIONS')}</div>}
            </div>{' '}
          </div>
        </OverlayPanel>
      </div>
    </div>
  )
}

export default ErrorMessageModal(NotificationListenService)
