import './styles.scss'
import React, { useEffect, useState } from 'react'
import { STATIC_ROUTE_STRINGS, socialMediaChannels } from 'Utils/Constants'
import { useTranslation } from 'react-i18next'
import { Facebook, WhatsApp, Instagram, Youtube, Twitter, LinkedIn, Quora, Tumblr, Telegram, Medium, Pinterest, Thread } from 'Assets/Icons'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import Package from '../../../../package.json'

const FooterDashboard = ({ mockScrollDirection }) => {
  const [scrollDirection, setScrollDirection] = useState('up')
  const userDetails = getLocalUserDetails()

  const { t } = useTranslation()

  // testing purpose
  useEffect(() => {
    setScrollDirection(mockScrollDirection)
  }, [])

  useEffect(() => {
    let prevScrollY = window.scrollY
    const handleScroll = () => {
      const windowWidth = window.innerWidth
      const currentScrollY = window.scrollY

      if (windowWidth < 767) {
        if (prevScrollY > currentScrollY) {
          if (currentScrollY < prevScrollY - 100) setScrollDirection('up')
          prevScrollY = currentScrollY
        } else {
          setScrollDirection('down')
          prevScrollY = currentScrollY
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const SocialMediaLink = ({ logo, altText, url, className }) => (
    <div className="social-icon-wrapper">
      <a href={url} target="_blank" rel="noopener noreferrer" className={`icon-container ${className}`} aria-label={altText}>
        {logo}
      </a>
    </div>
  )

  const leftSideLinks = [
    { url: 'https://coffeewebdotcom.quora.com/', logo: <Quora />, altText: 'quora', className: 'quora' },
    { url: 'https://www.tumblr.com/blog/coffeewebdotcom', logo: <Tumblr />, altText: 'tumblr', className: 'tumblr' },
    { url: 'https://t.me/CoffeeWebDotCom', logo: <Telegram />, altText: 'telegram', className: 'telegram' },
    { url: 'https://medium.com/@coffeewebdotcom', logo: <Medium />, altText: 'medium', className: 'medium' },
    { url: 'https://in.pinterest.com/CoffeeWebDotCom/', logo: <Pinterest />, altText: 'pinterest', className: 'pinterest' },
    { url: 'https://www.threads.net/@coffeewebdotcom', logo: <Thread />, altText: 'thread', className: 'thread' }
  ]

  const rightSideLinks = [
    { url: 'https://www.linkedin.com/company/coffeewebdotcom', logo: <LinkedIn />, altText: 'linkedin', className: 'linkedin' },
    { url: 'https://whatsapp.com/channel/0029Va5JryJDeONFtCLCVA0Z', logo: <WhatsApp />, altText: 'whatsapp', className: 'whatsapp' },
    { url: 'https://www.youtube.com/@CoffeeWebDotCom', logo: <Youtube />, altText: 'youtube', className: 'youtube' },
    { url: 'https://x.com/CoffeeWebDotCom', logo: <Twitter />, altText: 'twitter', className: 'twitter' },
    { url: 'https://www.instagram.com/coffeewebdotcom/', logo: <Instagram />, altText: 'instagram', className: 'instagram' },
    { url: 'https://www.facebook.com/CoffeeWebDotCom', logo: <Facebook />, altText: 'facebook', className: 'facebook' }
  ]

  return (
    <>
      <div className={`footer${scrollDirection === 'down' ? ' slide-down' : ' down'} ${userDetails ? ' center-content' : ''}`}>
        {!userDetails && (
          <div className="left-side-icons">
            {leftSideLinks.map(({ url, logo, altText, className }) => (
              <SocialMediaLink key={altText} url={url} logo={logo} altText={altText} className={className} />
            ))}
          </div>
        )}

        <div className="text-container" data-testid="scroll-direction">
          <div>
            <span>
              {t('COPYRIGHT')} <b>{t('COFFEE_WEB')}</b>
            </span>
            <span className="terms_and_condition">
              <span>{t('ALL_RIGHTS_RESERVED')}</span>
              <a href={STATIC_ROUTE_STRINGS.termsAndCondition} target="_blank" rel="noreferrer" data-testid="terms-and-condition">
                {t('TERMS_CONDITIONS')}
              </a>
              {t('AND')}
              <a href={STATIC_ROUTE_STRINGS.privacyPolicy} target="_blank" rel="noreferrer" data-testid="privacy-policy">
                {t('PRIVACY_POLICY')}
              </a>
            </span>
          </div>
        </div>
        {!userDetails && (
          <div className="right-side-icons">
            {rightSideLinks.map(({ url, logo, altText, className }) => (
              <SocialMediaLink key={altText} url={url} logo={logo} altText={altText} className={className} />
            ))}
          </div>
        )}
        {/* Todo: Add version number */}
        {/* <div className="version-number" data-testid="package-version">
          {Package.version}
        </div> */}
      </div>
    </>
  )
}

export default FooterDashboard
