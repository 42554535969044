/* eslint-disable */
import './style.scss'
import React, { memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import DialogUIModal from 'Components/UIComponent/DialogUIModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { commonMethods } from 'Utils/commonMethods'
import { arbitrageDummyData, dummyArbitrageHistoryData, dummyTerminalDifferenceHistoryData } from 'Utils/coffeeQuotesDummyData'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import ChartComponent from '../ChartComponent'
import CreateAccountInfoModal from 'Components/UIComponent/CreateAccountInfoModal'

const AccordianPopUp = memo(({ ...props }) => {
  const { item, accordianPopupListData, handleCloseAccordianModal, robustaData, arabicaData, mockSlideIndex, mockAccordionPopup, setLoading, testId } = props
  const { contractName, idMarket, openInterest, volume } = item
  const { previousDayColorLogic, getFontColor, updateDateTime, differenceValue, isMarketLive, getOptionExpiryColor, getFirstNoticeDayColor, getSign, getFontColorWithBold, convertTimeToUnixTimestampIST, checkForUserAccess, sentryLog, dateFormatBasedOnUser } = commonMethods
  const { t } = useTranslation()
  const history = useHistory()
  const { isMarketOpenToday } = isMarketLive()

  const currentDate = updateDateTime().currentDate
  const userDetails = getLocalUserDetails()
  const slidesTypes = [t('TERMINAL_CHART'), t('TERMINAL_DATA'), t('TERMINAL_DIFFERENCE'), t('ARBITRAGE')]
  const totalSlides = 4

  const isRobusta = idMarket === 1
  const decimalType = idMarket === 1 ? 0 : 2

  const [arbitrageLiveData, setArbitrageLiveData] = useState(arbitrageDummyData)
  const [slideIndex, setSlideIndex] = useState(1)
  const [terminalData, setTerminalData] = useState([])
  const [arbitrageData, setArbitrageData] = useState([])
  const [arbitrageHistoryData, setArbitrageHistoryData] = useState(dummyArbitrageHistoryData)
  const [terminalDifferenceHistoryData, setTerminalDifferenceHistoryData] = useState(dummyTerminalDifferenceHistoryData)
  const [currentHighlightedRow, setCurrentHighlightedRow] = useState(null)
  const [modifiedChartData, setModifiedChartData] = useState()
  const [todaysArbitrageHistory, setTodaysArbitrageHistory] = useState({
    terminal1Type: '--',
    terminal2Type: '--',
    terminal1Value: '--',
    terminal2Value: '--',
    differenceValue: 0
  })
  const [_52WeekHighValue, set52WeekHighValue] = useState({})
  const [_52WeekLowValue, set52WeekLowValue] = useState({})

  const lastChng = parseFloat(item?.lastChng || 0).toFixed(decimalType)
  const chng = parseFloat(item?.chng || 0).toFixed(decimalType)
  const percentageVal = parseFloat(item?.percentageVal || 0).toFixed(2)
  const highRate = parseFloat(item?.highRate || 0).toFixed(decimalType)
  const netHigh = parseFloat(item?.netHigh || 0).toFixed(decimalType)
  const netLow = parseFloat(item?.netLow || 0).toFixed(decimalType)
  const lowRate = parseFloat(item?.lowRate || 0).toFixed(decimalType)
  const openRate = parseFloat(item?.openRate || 0).toFixed(decimalType)
  const prevRate = parseFloat(item?.prevRate || 0).toFixed(decimalType)
  const bid = parseFloat(item?.bid || 0).toFixed(decimalType)
  const ask = parseFloat(item?.ask || 0).toFixed(decimalType)

  const closeValue = terminalData[0]?.close || 0
  const lastChangeValue = item?.idMarket === 1 ? (lastChng - closeValue).toFixed(0) : (lastChng - closeValue).toFixed(2)

  useEffect(() => {
    if (mockAccordionPopup) {
      setSlideIndex(mockSlideIndex)
    }
    getHistoryDatas()
  }, [])

  useEffect(() => {
    calculateArbitrageData()
    calculateTodaysLiveArbitrageHistory()
  }, [robustaData, arbitrageData])

  const getHistoryDatas = async () => {
    setLoading(true)

    try {
      const { data } = await apiAdapterCoffeeWeb.getAllTerminalAndArbitrageData({ contractName, idMarket })
      const response = data?.returnLst

      if (data.isSuccess) {
        setArbitrageData(response.arbitrageData)

        const modifiedArbitrageHistoryData = response?.arbitrageHistoryData?.map((ele, index) => {
          const { robustaValue, arabicaValue, convertedValue, terminalDateFormat } = ele

          return {
            ...ele,
            terminalDateFormat: dateFormatBasedOnUser(terminalDateFormat),
            terminal1TextColor: previousDayColorLogic(isRobusta ? robustaValue : arabicaValue, isRobusta ? response.arbitrageHistoryData[index + 1]?.robustaValue : response.arbitrageHistoryData[index + 1]?.arabicaValue),
            terminal2TextColor: previousDayColorLogic(convertedValue, response.arbitrageHistoryData[index + 1]?.convertedValue),
            differenceValueTextColor: getFontColor(isRobusta ? convertedValue - robustaValue : arabicaValue - convertedValue)
          }
        })

        setArbitrageHistoryData(modifiedArbitrageHistoryData)

        const modifiedTerminalDifferenceHistoryData = response.terminalDifferenceHistoryData?.map((ele, index) => {
          const { dateFormat, terminal1Value, terminal2Value, differenceValue } = ele

          return {
            ...ele,
            dateFormat: dateFormatBasedOnUser(dateFormat),
            terminal1Value,
            terminal2Value,
            differenceValue,
            terminal1ValueTextColor: previousDayColorLogic(terminal1Value, response.terminalDifferenceHistoryData[index + 1]?.terminal1Value),
            terminal2ValueTextColor: previousDayColorLogic(terminal2Value, response.terminalDifferenceHistoryData[index + 1]?.terminal2Value),
            differenceValueTextColor: getFontColor(terminal2Value - terminal1Value)
          }
        })

        setTerminalDifferenceHistoryData(modifiedTerminalDifferenceHistoryData)

        if (response?.terminalData?.length > 0) {
          handleTerminalData(response?.terminalData)
        }
      } else {
        handleCloseAccordianModal()
      }
    } catch (err) {
      setTerminalData([])
      set52WeekHighValue({})
      set52WeekLowValue({})
      handleCloseAccordianModal()
    } finally {
      setLoading(false)
    }
  }

  const handleTerminalData = (data) => {
    const dateCount = {}
    const newData = data?.map((ele) => {
      const { dateFormat, open, high, low, close, netChg, volume, comparedToPreDay, openInterest, comparedToPreDay1, date, contractName, idTerminal } = ele

      if (dateCount[date]) {
        sentryLog({ screenName: 'Coffee Quotes Popup', errorIn: `Charts with idTerminal ${idTerminal}`, description: `Duplicate in ${contractName} for Date ${date} with idTerminal ${idTerminal}`, contractName: `${contractName} ${idTerminal}`, date: `${idTerminal} , ${date}` })
      } else {
        dateCount[date] = 1
      }

      /* eslint-disable no-underscore-dangle */
      const isHighDateMatch = dateFormat.toUpperCase() === item?._52weeksHighDateFormat.toUpperCase()
      const isLowDateMatch = dateFormat.toUpperCase() === item?._52weeksLowDateFormat.toUpperCase()

      return {
        ...ele,
        dateFormat: dateFormatBasedOnUser(dateFormat),
        open: parseFloat(open).toFixed(decimalType),
        high: parseFloat(high).toFixed(decimalType),
        low: parseFloat(low).toFixed(decimalType),
        close: parseFloat(close).toFixed(decimalType),
        netChg: parseFloat(netChg).toFixed(decimalType),
        volume,
        comparedToPreDay,
        openInterest,
        comparedToPreDay1,
        /* eslint-disable no-nested-ternary */
        dateFormatTextColor: isHighDateMatch ? 'high-week' : isLowDateMatch ? 'low-week' : '',
        is52WeekHigh: isHighDateMatch,
        is52WeekLow: isLowDateMatch,
        closeTextColor: getFontColor(ele?.netChg),
        netChgTextColor: getFontColor(ele?.netChg),
        volumeTextColor: getFontColor(ele?.comparedToPreDay),
        comparedToPreDayTextColor: getFontColor(ele?.comparedToPreDay),
        openInterestTextColor: getFontColor(ele?.comparedToPreDay1),
        comparedToPreDay1TextColor: getFontColor(ele?.comparedToPreDay1)
      }
    })

    setTerminalData(newData || [])

    const modifiedDataForCandleSticks = newData.map((ele, index) => {
      const timeInseconds = convertTimeToUnixTimestampIST(ele?.date)

      const { idMarket, open, high, low, close, volume, is52WeekHigh, is52WeekLow, openInterest, netChg } = ele
      return {
        idMarket,
        time: timeInseconds,
        open: Number(open),
        high: Number(high),
        low: Number(low),
        close: Number(close),
        volume,
        value: Number(close),
        is52WeekHigh,
        is52WeekLow,
        openInterest,
        preClose: newData[index + 1]?.close || 0,
        netChg,
        netChgTextColor: getFontColor(ele?.netChg)
      }
    })

    const chartData = [...modifiedDataForCandleSticks.reverse()]
    setModifiedChartData(chartData)
    set52WeekHighValue(newData?.find(({ is52WeekHigh }) => is52WeekHigh))
    set52WeekLowValue(newData?.find(({ is52WeekLow }) => is52WeekLow))
  }

  const getNetHighAndLowColor = (ele) => {
    if (ele > 0) {
      return 'bold_and_green'
    }
    if (ele < 0) {
      return 'bold_and_red'
    }

    return ''
  }

  const terminalDataSlide = () => (
    <div className="slide_1" style={checkForUserAccess()} data-testid="slide-two">
      <div className="top-section">
        <div>
          <table className="contract_high_low">
            <thead>
              <tr className="contract_header">
                <td colSpan="2" className="contract_header_td">
                  52 {t('WEEK_HIGH')}
                </td>
              </tr>
              <tr className="contract_header_tr"></tr>
            </thead>
            <tbody>
              <tr className="contract_body" data-testid="slide-two-contract-body">
                <td className="weekHigh">{dateFormatBasedOnUser(item?._52weeksHighDateFormat).toUpperCase()}</td>
                <td className="weekHighValue">{item?._52weeksHighRate.toFixed(decimalType)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="terminal_heading" data-testid="slide-two-currentDate">
          {/* {currentDate} */}
          {dateFormatBasedOnUser()}
        </div>
        <div>
          <table className="contract_high_low">
            <thead>
              <tr className="contract_header">
                <td colSpan="2">52 {t('WEEK_LOW')}</td>
              </tr>
              <tr className="contract_header_tr"></tr>
            </thead>
            <tbody>
              <tr className="contract_body">
                <td className="weekLow">{dateFormatBasedOnUser(item?._52weeksLowDateFormat).toUpperCase()}</td>
                <td className="weekLowValue" data-testid="week-low-value">
                  {item?._52weeksLowRate.toFixed(decimalType)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="terminal_table" data-testid="slide-two-terminal-table">
        <div className="terminal_table_head" data-testid="slide-two-terminal-table-head">
          <div>{t('DATE')}</div>
          <div>{t('OPEN')}</div>
          <div>{t('HIGH')}</div>
          <div>{t('LOW')}</div>
          <div>{t('CLOSE')}</div>
          <div>{t('NET_CHG')}</div>
          <div>{t('VOLUME')}</div>
          <div>{t('COMPARED_TO_PREV_DAY')}</div>
          <div>{t('OPEN_INT')}</div>
          <div>{t('COMPARED_TO_PREV_DAY')}</div>
          <div style={{ borderLeft: '1px solid #50b3f6', borderRight: '1px solid #50b3f6' }}></div>
        </div>
        {terminalData?.length > 0 && (
          <div className="today_terminal_table_head" data-testid="today-terminal-head">
            {isMarketOpenToday && !item?.isMarketHoliday ? (
              <>
                <div>{t('TODAY')}</div>
                <div data-testid="open-rate-value">{openRate}</div>
                <div>{highRate}</div>
                <div>{lowRate}</div>
                <div className={previousDayColorLogic(lastChng, terminalData[0]?.close)}>{lastChng}</div>
                <div className={previousDayColorLogic(lastChng, terminalData[0]?.close)}>
                  {getSign(lastChangeValue)}
                  {lastChangeValue}
                </div>
                <div className={previousDayColorLogic(item?.volume, terminalData[0]?.volume)}>{item?.volume}</div>
                <div className={previousDayColorLogic(item?.volume, terminalData[0]?.volume)}>{(item?.volume || 0) - (terminalData[0]?.volume || 0)}</div>
                <div className={previousDayColorLogic(item?.openInterest, terminalData[0]?.openInterest)}>{item?.openInterest}</div>
                <div className={previousDayColorLogic(item?.openInterest, terminalData[0]?.openInterest)}>{(item?.openInterest || 0) - (terminalData[0]?.openInterest || 0)}</div>
                <div></div>
              </>
            ) : (
              <>
                <div>{t('TODAY')}</div>
                <div>--</div>
                <div>--</div>
                <div>--</div>
                <div>--</div>
                <div>--</div>
                <div>--</div>
                <div>--</div>
                <div>--</div>
                <div>--</div>
                <div></div>
              </>
            )}
          </div>
        )}
        <div style={{ height: '5px', borderBottom: '1px solid #50b3f6' }}></div>
        <div className="terminal_table_main_body">
          {terminalData?.map(({ dateFormat, open, high, low, close, netChg, volume, comparedToPreDay, openInterest, comparedToPreDay1, dateFormatTextColor, is52WeekHigh, is52WeekLow, closeTextColor, netChgTextColor, volumeTextColor, comparedToPreDayTextColor, openInterestTextColor, comparedToPreDay1TextColor }, index) => (
            <div key={index} className="terminal_table_body">
              <div className={dateFormatTextColor}>{dateFormat}</div>
              <div>{open}</div>
              <div className={is52WeekHigh ? 'high-week' : ''}>{high}</div>
              <div className={is52WeekLow ? 'low-week' : ''}>{low}</div>
              <div className={closeTextColor}>{close}</div>
              <div className={netChgTextColor}>
                {getSign(netChg)}
                {netChg}
              </div>
              <div className={volumeTextColor}>{volume}</div>
              <div className={comparedToPreDayTextColor}>{comparedToPreDay}</div>
              <div className={openInterestTextColor}>{openInterest}</div>
              <div className={comparedToPreDay1TextColor}>{comparedToPreDay1}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )

  const terminalDifferenceSlide = () => (
    <div className="slide_2" style={checkForUserAccess()}>
      <table className="difference_table" data-testid="contract-high-low">
        <thead>
          <tr>
            <th colSpan={3} data-testid="terminal-difference">
              {t('TERMINAL_DIFFERENCE')}
            </th>
          </tr>
          <tr className="difference_table_head" data-testid="difference-table-heading">
            <th>{t('TERMINALS')}</th>
            <th className="last_column">{t('LAST')}</th>
            <th>{t('DIFFERENCE')}</th>
          </tr>
          <tr className="table_tr"></tr>
        </thead>
        <tbody className="difference_table_body" data-testid="difference-table-body">
          <tr className="empty-divider">
            <td colSpan={3}>{/* this td should be empty */}</td>
          </tr>
          <>
            {accordianPopupListData.length ? (
              <>
                {accordianPopupListData?.map((ele, index) => (
                  /* eslint-disable no-nested-ternary */
                  <React.Fragment key={index}>
                    <tr className={currentHighlightedRow === index ? 'hoverable-row-terminal-difference' : ''} onMouseEnter={() => setCurrentHighlightedRow(index)} onMouseLeave={() => setCurrentHighlightedRow(null)}>
                      <td className={`${ele.parentLastChngColor} `}>{ele.parentTerminal}</td>
                      <td className={`${ele.parentLastChngColor} `}>{ele.parentLastChng !== '--' ? (item.idMarket === 1 ? ele.parentLastChng : ele.parentLastChng.toFixed(2)) : '--'}</td>
                      <td rowSpan="2" className={`${ele.differenceColor} differential_column`} style={{ border: '1px solid #50b3f6 !important' }}>
                        {ele.difference !== '--' ? (item.idMarket === 1 ? ele.difference : parseFloat(ele?.difference).toFixed(2)) : '--'}
                      </td>
                    </tr>
                    <tr className={currentHighlightedRow === index ? 'hoverable-row-terminal-difference' : ''} style={{ marginBottom: '5px' }} onMouseEnter={() => setCurrentHighlightedRow(index)} onMouseLeave={() => setCurrentHighlightedRow(null)}>
                      <td style={{ fontWeight: '400' }} className={`${ele.childLastChngColor} `}>
                        {ele.childTerminal}
                      </td>
                      <td style={{ fontWeight: '400' }} className={`${ele.childLastChngColor} `}>
                        {ele?.childTerminalChng !== '--' ? (item.idMarket === 1 ? ele.childTerminalChng : parseFloat(ele?.childTerminalChng).toFixed(2)) : '--'}
                      </td>
                    </tr>
                    <tr className="empty-divider">
                      <td colSpan={3}>{/* this td should be empty */}</td>
                    </tr>
                  </React.Fragment>
                  /* eslint-enable no-nested-ternary */
                ))}
              </>
            ) : (
              <tr className="no-data-available">
                <td className="message" colSpan={3}>
                  {t('NO_DATA_FOUND')}
                </td>
              </tr>
            )}
          </>
        </tbody>
      </table>
      <div>
        <div className="terminal_difference_history_wrapper" data-testid="terminal-difference-history-wrapper">
          <table>
            <thead>
              <tr>
                <th colSpan={4} data-testid="terminal-difference-history-data">
                  {t('TERMINAL_DIFFERENCE_HISTORY')} : {terminalDifferenceHistoryData?.[0]?.terminal1 || '--'} {t('VS')} {terminalDifferenceHistoryData?.[0]?.terminal2 || '--'}
                </th>
              </tr>
              <tr>
                <th>{t('DATE')}</th>
                <th>{terminalDifferenceHistoryData?.[0]?.terminal1 || '--'}</th>
                <th>{terminalDifferenceHistoryData?.[0]?.terminal2 || '--'}</th>
                <th>{t('DIFFERENCE')}</th>
              </tr>
            </thead>
            <tbody>
              {accordianPopupListData?.length > 0 && isMarketOpenToday && !item?.isMarketHoliday && terminalDifferenceHistoryData?.length > 0 ? (
                <tr className="bold">
                  <td>{t('TODAY')}</td>
                  <td className={previousDayColorLogic(parseFloat(accordianPopupListData[0]?.parentLastChng), parseFloat(terminalDifferenceHistoryData[0]?.terminal1Value))}>{accordianPopupListData[0]?.parentLastChng.toFixed(isRobusta ? 0 : 2)}</td>
                  <td className={previousDayColorLogic(parseFloat(accordianPopupListData[0]?.childTerminalChng), parseFloat(terminalDifferenceHistoryData[0]?.terminal2Value))}>{accordianPopupListData[0]?.childTerminalChng?.toFixed(isRobusta ? 0 : 2)}</td>
                  <td className={getFontColor(parseFloat(accordianPopupListData[0]?.childTerminalChng) - parseFloat(accordianPopupListData[0]?.parentLastChng))}>{(parseFloat(accordianPopupListData[0]?.childTerminalChng) - parseFloat(accordianPopupListData[0]?.parentLastChng))?.toFixed(isRobusta ? 0 : 2)}</td>
                </tr>
              ) : (
                <tr>
                  <td>{t('TODAY')}</td>
                  <td>--</td>
                  <td>--</td>
                  <td>--</td>
                </tr>
              )}
              {terminalDifferenceHistoryData?.map(({ dateFormat, terminal1Value, terminal2Value, differenceValue, terminal1ValueTextColor, terminal2ValueTextColor, differenceValueTextColor }, index) => (
                <tr key={index}>
                  <td data-testid="date-format">{dateFormat}</td>
                  <td data-testid="terminal1-value" className={`bold ${terminal1ValueTextColor}`}>
                    {terminal1Value}
                  </td>
                  <td data-testid="terminal2-value" className={`${terminal2ValueTextColor}`}>
                    {terminal2Value}
                  </td>
                  <td data-testid="difference-value" className={`bold ${differenceValueTextColor}`}>
                    {differenceValue}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

  const calculateArbitrageData = () => {
    if (arbitrageData) {
      const newArray = []

      for (let i = 0; i < arbitrageData?.length; i++) {
        const findRobustaTerminal = robustaData?.find(({ terminalId }) => terminalId === arbitrageData[i]?.robustaContractId)
        const findArabicaTerminal = arabicaData?.find(({ terminalId }) => terminalId === arbitrageData[i]?.arabicaContractId)

        const newObj = {
          robustaTerminal: arbitrageData[i]?.robustaTerminal,
          arabicaTerminal: arbitrageData[i]?.arabicaTerminal
        }

        if (findRobustaTerminal) {
          newObj.robustaValue = findRobustaTerminal?.lastChng
          newObj.robustaChng = findRobustaTerminal?.chng
          newObj.robustaActualValue = findRobustaTerminal?.lastChng
        }

        if (findArabicaTerminal) {
          newObj.arabicaValue = parseFloat(findArabicaTerminal.lastChng * 22.0462).toFixed(0)
          newObj.arabicaChng = findArabicaTerminal?.chng
          newObj.arabicaActualValue = findArabicaTerminal?.lastChng.toFixed(2)
        }

        newObj.differenceValue = Math.abs(parseFloat(newObj.robustaValue) - parseFloat(newObj.arabicaValue))

        if (!isRobusta && findArabicaTerminal) {
          newObj.robustaValue = (newObj.robustaValue /= 22.0462).toFixed(2)
          newObj.arabicaValue = findArabicaTerminal.lastChng.toFixed(2)
          newObj.differenceValue = Math.abs(findArabicaTerminal?.lastChng.toFixed(2) - newObj.robustaValue).toFixed(2)
        }

        newArray.push(newObj)
      }

      setArbitrageLiveData(newArray)
    }
  }

  const calculateTodaysLiveArbitrageHistory = () => {
    if (arbitrageHistoryData?.length > 0) {
      const todaysObject = {}
      const terminal1Month = arbitrageHistoryData[0]?.robustaTerminal
      const terminal2Month = arbitrageHistoryData[0]?.arabicaTerminal
      const actualMarketType = isRobusta ? arabicaData : robustaData
      const findContract = actualMarketType?.find(({ terminalId }) => terminalId === (isRobusta ? arbitrageHistoryData[0]?.arabicaContractId : arbitrageHistoryData[0]?.robustaContractId))

      todaysObject.terminal1Type = isRobusta ? terminal1Month : terminal2Month
      todaysObject.terminal2Type = isRobusta ? terminal2Month : terminal1Month
      todaysObject.terminal1Value = isRobusta ? item?.lastChng : item?.lastChng.toFixed(2)
      todaysObject.terminal2Value = isRobusta ? (parseFloat(findContract?.lastChng || 0) * 22.0461).toFixed(0) : (parseFloat(findContract?.lastChng || 0) / 22.0462).toFixed(2)
      todaysObject.terminal1TextColor = getFontColor(item?.chng || 0)
      todaysObject.terminal2TextColor = getFontColor(findContract?.chng || 0)
      todaysObject.differenceValue = differenceValue(todaysObject?.terminal1Value, todaysObject?.terminal2Value)
      todaysObject.robustaActualValue = findContract?.lastChng.toFixed(0)
      todaysObject.arabicaActualValue = findContract?.lastChng.toFixed(2)

      setTodaysArbitrageHistory(todaysObject)
    }
  }

  const arbitrageSlide = () => (
    <div className="slide_3" style={checkForUserAccess()}>
      <div className="arbritage_data_wrapper" data-testid="arbritage-data-wrapper">
        <table>
          <thead>
            <tr>
              <th colSpan={5}>
                {t('ARBITRAGE')}{' '}
                {isRobusta ? (
                  <>
                    {t('ROBUSTA_VS_ARABICA')} {t('IN_USD')}
                  </>
                ) : (
                  <>
                    {t('ARABICA_VS_ROBUSTA')} {t('IN_CENTS')}
                  </>
                )}
              </th>
            </tr>
            <tr>
              <th data-testid="fourth-slide-robusta-heading" colSpan={2}>
                {isRobusta ? <>{t('ROBUSTA')}</> : <>{t('ARABICA')}</>}
              </th>
              <th data-testid="fourth-slide-arabica-heading" colSpan={2}>
                {isRobusta ? <>{t('ARABICA')}</> : <>{t('ROBUSTA')}</>}
              </th>
              <th data-testid="fourth-slide-difference-heading">{t('DIFFERENCE')}</th>
            </tr>
          </thead>
          <tbody>
            {arbitrageLiveData?.map(({ robustaTerminal, robustaValue, robustaChng, arabicaChng, arabicaValue, arabicaTerminal, convertedValue, differenceValue, robustaActualValue, arabicaActualValue }, index) => (
              <tr key={index}>
                <td className="bold">{isRobusta ? robustaTerminal : arabicaTerminal}</td>
                <td className={getFontColor(isRobusta ? robustaChng : arabicaChng)}>{isRobusta ? robustaValue || 0 : arabicaValue || 0}</td>
                <td className="bold">{isRobusta ? arabicaTerminal : robustaTerminal}</td>
                <td className={getFontColor(isRobusta ? arabicaChng : robustaChng)}>
                  {isRobusta ? arabicaValue || 0 : robustaValue || 0}
                  <span className="reference-value">({isRobusta ? arabicaActualValue : robustaActualValue})</span>
                </td>
                <td className={`bold ${getFontColor(differenceValue)}`}>{differenceValue || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="arbritage_history_data_wrapper">
        <table>
          <thead>
            <tr>
              <th colSpan={6}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isRobusta ? <>{t('ROBUSTA')}</> : <>{t('ARABICA')}</>} {arbitrageHistoryData?.[0]?.robustaTerminal !== arbitrageHistoryData?.[0]?.arabicaTerminal ? (isRobusta ? arbitrageHistoryData?.[0]?.robustaTerminal : arbitrageHistoryData?.[0]?.arabicaTerminal) : ''} VS {isRobusta ? <>{t('ARABICA')}</> : <>{t('ROBUSTA')}</>} {isRobusta ? arbitrageHistoryData?.[0]?.arabicaTerminal : arbitrageHistoryData?.[0]?.robustaTerminal} {t('TERMINAL_ARBITRAGE_HISTORY')} {isRobusta ? t('IN_USD') : t('IN_CENTS')}
              </th>
            </tr>
            <tr>
              <th>{t('DATE')}</th>
              <th colSpan={2}>{isRobusta ? <>{t('ROBUSTA')}</> : <>{t('ARABICA')}</>}</th>
              <th colSpan={2}>{isRobusta ? <>{t('ARABICA')}</> : <>{t('ROBUSTA')}</>}</th>
              <th>{t('DIFFERENCE')}</th>
            </tr>
          </thead>
          <tbody>
            {isMarketOpenToday && !item?.isMarketHoliday && arbitrageHistoryData?.length > 0 ? (
              <tr className="bold">
                <td>{t('TODAY')}</td>
                <td>{todaysArbitrageHistory?.terminal1Type}</td>
                <td className={todaysArbitrageHistory?.terminal1TextColor}>{todaysArbitrageHistory?.terminal1Value}</td>
                <td>{todaysArbitrageHistory?.terminal2Type}</td>
                <td className={todaysArbitrageHistory?.terminal2TextColor}>
                  {todaysArbitrageHistory?.terminal2Value}
                  <span className="reference-value">({isRobusta ? todaysArbitrageHistory?.arabicaActualValue || '--' : todaysArbitrageHistory?.robustaActualValue || '--'})</span>
                </td>
                <td className={getFontColor(isRobusta ? parseFloat(todaysArbitrageHistory?.terminal2Value) - parseFloat(todaysArbitrageHistory?.terminal1Value) : parseFloat(todaysArbitrageHistory?.terminal1Value) - parseFloat(todaysArbitrageHistory?.terminal2Value))}>{todaysArbitrageHistory?.differenceValue?.toFixed(isRobusta ? 0 : 2)}</td>
              </tr>
            ) : (
              <tr>
                <td>{t('TODAY')}</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
              </tr>
            )}
            {arbitrageHistoryData?.map(({ terminalDateFormat, robustaTerminal, robustaValue, arabicaTerminal, arabicaValue, convertedValue, differenceValue, terminal1TextColor, terminal2TextColor, differenceValueTextColor }, index) => (
              <tr key={index}>
                <td>{terminalDateFormat}</td>
                <td className="bold">{isRobusta ? robustaTerminal : arabicaTerminal}</td>
                <td className={terminal1TextColor}>{isRobusta ? robustaValue : arabicaValue}</td>
                <td className="bold">{isRobusta ? arabicaTerminal : robustaTerminal}</td>
                <td className={terminal2TextColor}>
                  {convertedValue}
                  <span className="reference-value">({isRobusta ? arabicaValue : robustaValue})</span>
                </td>
                <td className={`bold ${differenceValueTextColor}`}>{differenceValue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

  const handleClickRightArrow = () => setSlideIndex(slideIndex === totalSlides ? 1 : slideIndex + 1)
  const handleClickLeftArrow = () => setSlideIndex(slideIndex === 1 ? totalSlides : slideIndex - 1)

  const getButtonAndInfo = () => {
    if (!userDetails) {
      return {
        buttonText: t('LOGIN_AND_CREATE_ACCOUNT'),
        infoMessage: t('TO_ACCESS_MORE_FEATURE_CREATE_ACCOUNT'),
        className: 'login-button',
        navigationPath: ROUTE_STRINGS.login
      }
    }

    return {}
  }

  const { buttonText, infoMessage, className, navigationPath } = getButtonAndInfo()

  const [todayLiveTerminalData, setTodayLiveTerminalData] = useState({})

  useEffect(() => {
    const now = new Date()
    const utcDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()))
    const unixTimestamp = Math.floor(utcDate.getTime() / 1000)

    setTodayLiveTerminalData({
      idMarket,
      time: unixTimestamp,
      open: Number(openRate),
      high: Number(highRate),
      low: Number(lowRate),
      close: Number(lastChng),
      volume: Number(volume),
      value: Number(highRate),
      is52WeekHigh: item?.todayLiveTerminalData,
      is52WeekLow: false,
      openInterest: Number(openInterest),
      preClose: Number(prevRate),
      netChg: Number(chng),
      netChgTextColor: Number(chng) >= 0 ? 'green-text' : 'red-text',
      todayPrice: true
    })
  }, [item])

  const WeekHighForCandleStick = () => (
    <table className="contract_high_low" data-testid="contract-high-low">
      <thead>
        <tr className="contract_header">
          <td colSpan="2" className="contract_header_td">
            52 {t('WEEK_HIGH')}
          </td>
        </tr>
        <tr className="contract_header_tr"></tr>
      </thead>
      <tbody>
        <tr className="contract_body">
          <td className="weekHigh">{dateFormatBasedOnUser(item?._52weeksHighDateFormat).toUpperCase()}</td>
          <td className="weekHighValue">{item?._52weeksHighRate.toFixed(decimalType)}</td>
        </tr>
      </tbody>
    </table>
  )

  const WeekLowForCandleStick = () => (
    <table className="contract_high_low">
      <thead>
        <tr className="contract_header">
          <td colSpan="2">52 {t('WEEK_LOW')}</td>
        </tr>
        <tr className="contract_header_tr"></tr>
      </thead>
      <tbody>
        <tr className="contract_body">
          <td className="weekLow">{dateFormatBasedOnUser(item?._52weeksLowDateFormat).toUpperCase()}</td>
          <td className="weekLowValue">{item?._52weeksLowRate.toFixed(decimalType)}</td>
        </tr>
      </tbody>
    </table>
  )

  const navigateToLoginScreen = () => history.push(ROUTE_STRINGS.login)

  const candleStickSlide = () => (
    <div className="candlestick-slide-wrapper" data-testid="candleStick-slide-wrapper">
      <div className="candlestick-slide-52weekHighLow-wrapper" data-testid="candleStick-slide-52week-highLow-wrapper">
        <div>
          <WeekHighForCandleStick />
        </div>
        {!userDetails ? (
          <div className="auth-screen-button" data-testid="auth-screen-buttons" onClick={navigateToLoginScreen}>
            {t('LOGIN_AND_CREATE_ACCOUNT')}
          </div>
        ) : (
          <div className="contract-name">{contractName}</div>
        )}
        <div data-test-id="week-low-candle-stick">
          <WeekLowForCandleStick />
        </div>
      </div>
      {modifiedChartData?.length > 0 && <ChartComponent terminalData={isMarketOpenToday && !item?.isMarketHoliday ? [...modifiedChartData, todayLiveTerminalData] : modifiedChartData} contractName={contractName} _52WeekHighValue={_52WeekHighValue} _52WeekLowValue={_52WeekLowValue} todayLiveTerminalData={todayLiveTerminalData} WeekHighForCandleStick={WeekHighForCandleStick} WeekLowForCandleStick={WeekLowForCandleStick} isMarketOpenToday={isMarketOpenToday && !item?.isMarketHoliday} />}
    </div>
  )

  const dailogModalContent = () => (
    <div className="terminalDetailsModalPopup" data-testid="terminal-details-modal-popup">
      {!userDetails && slideIndex !== 1 && (
        <div className="accordian-create-account-info-modal-popup">
          <CreateAccountInfoModal buttonText={buttonText} infoMessage={infoMessage} className={className} navigationPath={navigationPath} />
        </div>
      )}
      <div>
        <div className="terminal_detail_table" data-testid="terminal-details-table">
          <table>
            <thead>
              <tr>
                <td>{t('CONTRACT_TYPE')}</td>
                <td>{t('LAST')}</td>
                <td>{t('CHG')}</td>
                <td>%</td>
                <td>{t('VOLUME')}</td>
                <td>{t('HIGH')}</td>
                <td>{t('NET_HIGH')}</td>
                <td>{t('LOW')}</td>
                <td>{t('NET_LOW')}</td>
                <td>{t('OPEN')}</td>
                <td>{t('PRE_CLOSE')}</td>
                <td>{t('OPEN_INT')}</td>
                <td>{t('BID')}</td>
                <td>{t('B_SIZE')}</td>
                <td>{t('ASK')}</td>
                <td>{t('A_SIZE')}</td>
                <td>{t('OPTION_EXPIRY')}</td>
                <td>{t('FIRST_NOTICE_DAY')}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* contractName */}
                <td className="table_td">{item?.contractName}</td>

                {/* lastChng */}
                <td className={getFontColorWithBold(item?.chng)} data-title="Last" data-testid="last-change-value">
                  {lastChng}
                </td>

                {/* chng */}
                <td className={getFontColorWithBold(item?.chng)} data-title="Chg">
                  {item?.chng > 0 && '+'}
                  {chng}
                </td>

                {/* % */}
                <td className="percentage" data-title="%">
                  {item?.percentageVal > 0 && '+'}
                  {percentageVal}
                </td>

                {/* Volume */}
                <td className="volume" data-title="Vol">
                  {item?.volume}
                </td>

                {/* High */}
                <td data-title="High">{highRate}</td>

                {/* Net-High */}
                <td data-title="$-High" data-testid="net-high-value">
                  <span className={getNetHighAndLowColor(item?.netHigh)}>
                    {item?.netHigh > 0 && '+'}
                    {netHigh}
                  </span>
                </td>

                {/* Low */}
                <td data-title="Low">{lowRate}</td>

                {/* Net-Low */}
                <td data-title="$-Low" data-testid="net-low-value">
                  <span className={getNetHighAndLowColor(item?.netLow)}>
                    {item?.netLow > 0 && '+'}
                    {netLow}
                  </span>
                </td>

                {/* Open */}
                <td data-title="Open">{openRate}</td>

                {/* Pre Close */}
                <td data-title="Pre-Clos">{prevRate}</td>

                {/* Open Int */}
                <td data-title="Open Int">{item?.openInterest}</td>

                {/* Bid */}
                <td data-title="Bid">{bid}</td>

                {/* B-size */}
                <td data-title="Bsize">{item?.bsize}</td>

                {/* Ask */}
                <td data-title="Ask">{ask}</td>

                {/* A-size */}
                <td data-title="Let's help">{item?.asize}</td>

                {/* Option Expiry */}
                <td data-title="Option Expiry" className={getOptionExpiryColor(item?.optionExpiryStatus)}>
                  {item?.optionExpiryDateFormat.toUpperCase()}
                </td>

                {/* First Notice Date */}
                <td data-title="First Notice Day" className={getFirstNoticeDayColor(item?.firstNoticeDateStatus)}>
                  {item?.firstNoticeDateFormat.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="terminal_detail_body">
          <div className="arrows" onClick={() => handleClickLeftArrow()}>
            <i className="pi pi-angle-double-left angle-double-icon" />
          </div>

          {/* slide 1 */}
          {slideIndex === 1 && <div>{candleStickSlide()}</div>}

          {/* slide 2 */}
          {slideIndex === 2 && <div>{terminalDataSlide()}</div>}

          {/* slide 3 */}
          {slideIndex === 3 && <div>{terminalDifferenceSlide()}</div>}

          {/* slide 4 */}
          {slideIndex === 4 && <div>{arbitrageSlide()}</div>}

          <div className="arrows" onClick={() => handleClickRightArrow()}>
            <i className="pi pi-angle-double-right angle-double-icon" />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div data-testid={testId}>
      <DialogUIModal size="lg" titlePosition="center" title={slidesTypes[slideIndex - 1]} showModal={true} handleClose={handleCloseAccordianModal} bodyContent={dailogModalContent} />
    </div>
  )
})

export default AccordianPopUp
