import './styles.scss'
import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { getLocalUserDetails } from 'Utils/LocalStorage_Handler'
import { commonMethods } from 'Utils/commonMethods'
import { coffeeReportJsonLinks } from 'Utils/Constants'
import { CoffeeWebContext } from 'Context/CoffeeWebContext'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'

const PortStockReports = ({ selectedMarket, setSelectedMarket, mockTotalCertifiedStocksData }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const fullPath = location.pathname
  const pathSegments = fullPath.split('/')
  const desiredPath = pathSegments[2] || ''
  const userDetails = getLocalUserDetails()
  const { checkForUserAccess, dateFormatBasedOnUser } = commonMethods
  const { setLoading } = useContext(CoffeeWebContext)

  const { robustaCountryWiseCertifiedStocks, arabicaCountryWiseCertifiedStocks } = coffeeReportJsonLinks

  let menuLock

  if (userDetails && userDetails.menus) {
    const menu = userDetails?.menus?.find(({ menuUrl }) => menuUrl === desiredPath)

    if (menu) {
      menuLock = menu.menulock
    }
  }

  const [swapReport, setSwapReport] = useState('Metric Tons')
  const [fetchedData, setFetchedData] = useState([])

  useEffect(() => {
    fetchData()
    if (selectedMarket) {
      setSwapReport('Metric Tons')
    }
  }, [selectedMarket])

  // useEffect to initialize state for testing purpose
  useEffect(() => {
    if (mockTotalCertifiedStocksData) {
      setFetchedData(mockTotalCertifiedStocksData)
    }
  }, [])

  const fetchData = async () => {
    setLoading(true)
    const reportName = selectedMarket === 'Robusta' ? robustaCountryWiseCertifiedStocks : arabicaCountryWiseCertifiedStocks

    try {
      const response = await apiAdapterCoffeeWeb.getDataByReportName({ reportName })

      setFetchedData(response.data)
    } finally {
      setLoading(false)
    }
  }

  const toggleSwapOption = () => {
    setSwapReport((prevOption) => {
      if (selectedMarket === 'Robusta') {
        return prevOption === 'Metric Tons' ? 'LOTS' : 'Metric Tons'
      }
      if (selectedMarket === 'Arabica') {
        return prevOption === 'Metric Tons' ? '60 KG BAGS' : 'Metric Tons'
      }

      return 'Metric Tons'
    })
  }

  const convertMTToBags = (valueInMT) => {
    if (valueInMT === null || Number.isNaN(Number(valueInMT))) {
      return ''
    }
    const result = (Number(valueInMT) * 1000) / 60

    return Math.round(result)
  }

  const determineTextColor = (differenceFromPreviousDay) => {
    if (differenceFromPreviousDay === 0) {
      return 'neutral'
    }
    if (differenceFromPreviousDay > 0) {
      return 'positive'
    }

    return 'negative'
  }

  const determineCellClass = (currentValue, previousValue) => {
    if (currentValue > previousValue) {
      return 'positive'
    }
    if (currentValue < previousValue) {
      return 'negative'
    }

    return 'neutral'
  }

  const robustaFlags = [
    {
      port: 'AMS-Amsterdam',
      countryName: 'Netherlands',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/NLD.svg'
    },
    {
      port: 'ANT-Antwerp',
      countryName: 'Belgium',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/BEL.svg'
    },
    {
      port: 'BAR-Barcelona',
      countryName: 'Spain',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/ESP.svg'
    },
    {
      port: 'BRE-Bremen',
      countryName: 'Germany',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/DEU.svg'
    },
    {
      port: 'FEL-Felixstowe',
      countryName: 'England',
      flagsUrl: 'https://upload.wikimedia.org/wikipedia/en/b/be/Flag_of_England.svg'
    },
    {
      port: 'GEN-Genoa',
      countryName: 'Italy',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/ITA.svg'
    },
    {
      port: 'HAM-Hamburg',
      countryName: 'Germany',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/DEU.svg'
    },
    {
      port: 'LEH-Lehavre',
      countryName: 'France',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/FRA.svg'
    },
    {
      port: 'LIV-Liverpool',
      countryName: 'England',
      flagsUrl: 'https://upload.wikimedia.org/wikipedia/en/b/be/Flag_of_England.svg'
    },
    {
      port: 'LON-London',
      countryName: 'England',
      flagsUrl: 'https://upload.wikimedia.org/wikipedia/en/b/be/Flag_of_England.svg'
    },
    {
      port: 'NOR-Norwich',
      countryName: 'England',
      flagsUrl: 'https://upload.wikimedia.org/wikipedia/en/b/be/Flag_of_England.svg'
    },
    {
      port: 'ROT-Rotterdam',
      countryName: 'Netherlands',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/NLD.svg'
    },
    {
      port: 'TRI-Trieste',
      countryName: 'Italy',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/ITA.svg'
    }
  ]

  const arabicaFlags = [
    {
      countryName: 'Brazil',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/BRA.svg'
    },
    {
      countryName: 'Burundi',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/BDI.svg'
    },
    {
      countryName: 'Colombia',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/COL.svg'
    },
    {
      countryName: 'Costa Rica',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Flag-Costa-Rica.svg'
    },
    {
      countryName: 'El Salvador',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/SLV.svg'
    },
    {
      countryName: 'Guatemala',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/GTM.svg'
    },
    {
      countryName: 'Honduras',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/HND.svg'
    },
    {
      countryName: 'India',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/IND.svg'
    },
    {
      countryName: 'Kenya',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/KEN.svg'
    },
    {
      countryName: 'Mexico',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/MEX.svg'
    },
    {
      countryName: 'Nicaragua',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/NIC.svg'
    },
    {
      countryName: 'Papua New Guinea',
      flagsUrl: 'https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg'
    },
    {
      countryName: 'Peru',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/PER.svg'
    },
    {
      countryName: 'Rwanda',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/RWA.svg'
    },
    {
      countryName: 'Tanzania',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/TZA.svg'
    },
    {
      countryName: 'Uganda',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/UGA.svg'
    },
    {
      countryName: 'Venezuela',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/VEN.svg'
    }
  ]

  const arabicaWarehouseColumns = [
    {
      warehouseName: 'ANTWERP_COLUMN',
      countryName: 'Belgium',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/BEL.svg'
    },
    {
      warehouseName: 'BARCELONA_COLUMN',
      countryName: 'Spain',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/ESP.svg'
    },
    {
      warehouseName: 'HAMBURG_BREMEN',
      countryName: 'Germany',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/DEU.svg'
    },
    {
      warehouseName: 'HOUSTON_COLUMN',
      countryName: 'United States',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/USA.svg'
    },
    {
      warehouseName: 'MIAMI_COLUMN',
      countryName: 'United States',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/USA.svg'
    },
    {
      warehouseName: 'NEW_ORLEANS',
      countryName: 'United States',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/USA.svg'
    },
    {
      warehouseName: 'NEW_YORK_COLUMN',
      countryName: 'United States',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/USA.svg'
    },
    {
      warehouseName: 'VIRGINIA_COLUMN',
      countryName: 'United States',
      flagsUrl: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/USA.svg'
    }
  ]

  const transformData = (data, marketType) => {
    const portData = data?.map((ele, index) => {
      const portObject = ele?.report.map((item, secondIndex) => {
        const currentValid = item?.valid
        const previousValid = ele?.report[secondIndex + 1]?.valid
        const validClass = determineCellClass(currentValid, previousValid)

        const currentAntwerp = item?.Antwerp
        const previousAntwerp = ele?.report[secondIndex + 1]?.Antwerp
        const antwerpClass = determineCellClass(currentAntwerp, previousAntwerp)

        const currentBarcelona = item?.barcelona
        const previousBarcelona = ele?.report[secondIndex + 1]?.barcelona
        const barcelonaClass = determineCellClass(currentBarcelona, previousBarcelona)

        const currentHamburgBremen = item?.HamburgBremen
        const previousHamburgBremen = ele?.report[secondIndex + 1]?.HamburgBremen
        const hamburgBremenClass = determineCellClass(currentHamburgBremen, previousHamburgBremen)

        const currentHouston = item?.Houston
        const previousHouston = ele?.report[secondIndex + 1]?.Houston
        const houstonClass = determineCellClass(currentHouston, previousHouston)

        const currentMiami = item?.miami
        const previousMiami = ele?.report[secondIndex + 1]?.miami
        const miamiClass = determineCellClass(currentMiami, previousMiami)

        const currentNewOrleans = item?.NewOrleans
        const previousNewOrleans = ele?.report[secondIndex + 1]?.NewOrleans
        const newOrleansClass = determineCellClass(currentNewOrleans, previousNewOrleans)

        const currentNewYork = item?.NewYork
        const previousNewYork = ele?.report[secondIndex + 1]?.NewYork
        const newYorkClass = determineCellClass(currentNewYork, previousNewYork)

        const currentVirginia = item?.Virginia
        const previousVirginia = ele?.report[secondIndex + 1]?.Virginia
        const virginiaClass = determineCellClass(currentVirginia, previousVirginia)

        const currentTotal = currentAntwerp + currentBarcelona + currentHamburgBremen + currentHouston + currentMiami + currentNewOrleans + currentNewYork + currentVirginia
        const previousTotal = previousAntwerp + previousBarcelona + previousHamburgBremen + previousHouston + previousMiami + previousNewOrleans + previousNewYork + previousVirginia
        const totalClass = determineCellClass(currentTotal, previousTotal)

        return {
          ...item,
          currentValid,
          validClass,
          antwerpClass,
          barcelonaClass,
          hamburgBremenClass,
          houstonClass,
          miamiClass,
          newOrleansClass,
          newYorkClass,
          virginiaClass,
          totalClass
        }
      })

      return { ...ele, flagUrl: (marketType === 'Robusta' ? robustaFlags : arabicaFlags)[index]?.flagsUrl, countryName: (marketType === 'Robusta' ? robustaFlags : arabicaFlags)[index]?.countryName, report: portObject }
    })

    return portData
  }

  const formatRobustaReportValue = (value, reportType) => {
    const updatedData = reportType === 'Metric Tons' ? value?.toLocaleString('en-US') : (value / 10)?.toLocaleString('en-US')

    return updatedData
  }

  const formatArabicaReportValue = (value) => {
    const updatedValue = swapReport === 'Metric Tons' ? Math.round(value)?.toLocaleString('en-US') : convertMTToBags(value)?.toLocaleString('en-US')

    return updatedValue
  }

  const handleMarketChange = () => {
    setSelectedMarket(selectedMarket === 'Robusta' ? 'Arabica' : 'Robusta')
    setFetchedData([])
  }

  return (
    <div className="port-report-content" data-testid="port-stock-report-main-container">
      <div className="port-report-header" data-testid="port-stock-report-header-container">
        <div className="swap-content-wrapper" onClick={handleMarketChange} data-testid="contract-name-container">
          {selectedMarket}
          <div className="swap-button" data-testid="contract-arrow-toggle-container">
            <i className="pi pi-angle-up upper-arrow" data-testid="arrow-up-icon" />
            <i className="pi pi-angle-down down-arrow" data-testid="arrow-down-icon" />
          </div>
        </div>

        <div className="title-cell">{selectedMarket === 'Robusta' ? <>{t('PORT_WISE_CERTIFIED_STOCKS')}</> : <>{t('COUNTRYWISE_CERTIFIED_STOCK')}</>} </div>
        <div className="swap-content-wrapper" onClick={toggleSwapOption} data-testid="swap-content-wrapper">
          {swapReport}
          <div className="swap-button" data-testid="quantity-swap-button">
            <i className="pi pi-angle-up upper-arrow" data-testid="quantity-arrow-up-icon" />
            <i className="pi pi-angle-down down-arrow" data-testid="quantity-arrow-down-icon" />
          </div>
        </div>
      </div>
      <div className="horizontal-scroll-container" data-testid="port-stock-report-table-container">
        {selectedMarket === 'Robusta' &&
          transformData(fetchedData, 'Robusta')?.map(({ report, port, flagUrl, countryName }, index) => (
            <div key={index} className="table-wrapper robusta-table-wrapper">
              <table>
                <thead>
                  <tr className="port-name-wrapper" data-testid="port-name-wrapper">
                    <th colSpan={6}>
                      <div className="port-name">
                        <div>PORT :</div>
                        <img src={flagUrl} alt="port-country-flag" className="flag-icon" title="port-country-flag" />
                        <div className="port-type" data-testid="port-type-container">
                          {port}
                        </div>
                        <div className="country-type" data-testid="country-type-container">
                          - {countryName}
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th className="report-date">{t('REPORT_DATE')}</th>
                    <th className="certified-stocks">{t('VALID')}</th>
                    <th className="compare-to-pre-day">{t('COMPARE_TO_PRE_DAY')}</th>
                    <th className="non-tenderable">{t('NON_TENDERABLE')}</th>
                    <th className="suspended">{t('SUSPENDED')}</th>
                  </tr>
                  <tr className="empty-row"></tr>
                </thead>
                <tbody style={checkForUserAccess(menuLock)}>
                  {report?.map(({ date, valid, comparedToPreDay, nonTenderable, suspended, validClass }, index) => (
                    <tr key={index}>
                      <td data-testid="port-report-date-container">{dateFormatBasedOnUser(date)}</td>
                      <td className={validClass} data-testid="port-report-valid-container">
                        {formatRobustaReportValue(valid, swapReport)}
                      </td>
                      <td className={determineTextColor(comparedToPreDay)} data-testid="port-report-compared-pre-day-container">
                        {formatRobustaReportValue(comparedToPreDay, swapReport)}
                      </td>
                      <td data-testid="port-report-non-tenderable-container">{formatRobustaReportValue(nonTenderable, swapReport)}</td>
                      <td data-testid="port-report-suspended-container">{formatRobustaReportValue(suspended, swapReport)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}

        {selectedMarket === 'Arabica' &&
          transformData(fetchedData, 'Arabica')?.map(({ country, report, flagUrl }, index) => (
            <div key={index} className="table-wrapper arabica-table-wrapper" data-testid="arabica-table-main-container">
              <table>
                <thead>
                  <tr className="port-name-wrapper" data-testid="port-name-wrapper">
                    <th colSpan={12}>
                      <div className="port-name">
                        <div>{t('COUNTRY_NAME')} :</div>
                        <img src={flagUrl} className="flag-icon" alt="Flag" title="arabica-port-country-flag" />
                        <div className="country-type" data-testid="port-country-type-container">
                          {country}
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={12} className="warehouses-header">
                      {t('WAREHOUSES_TITLE')}
                    </th>
                  </tr>
                  <tr>
                    <th className="warehouse-date">{t('REPORT_DATE')}</th>
                    {arabicaWarehouseColumns.map((el, i) => (
                      <th className="port-name-column" key={i}>
                        <div className="flag-country-container" data-testid="flag-country-container">
                          <img src={el.flagsUrl} alt="country-flag" title="port-country-flag" />
                          <div className="warehouse-name" data-testid="warehouse-name-container">
                            {t(el.warehouseName)}
                          </div>
                        </div>
                        <div>
                          <div className="country-name" data-testid="warehouse-country-container">
                            ({el.countryName})
                          </div>
                        </div>
                      </th>
                    ))}
                    <th className="warehouse-total">{t('TOTAL_VALUE')}</th>
                    <th className="warehouse-compare-to-previous-day">{t('COMPARE_TO_PRE_DAY')}</th>
                  </tr>
                  <tr className="empty-row"></tr>
                </thead>
                <tbody style={checkForUserAccess(menuLock)}>
                  {report.map(({ Date, Antwerp, barcelona, HamburgBremen, Houston, miami, NewOrleans, NewYork, Virginia, ComparedPrevDay, antwerpClass, barcelonaClass, hamburgBremenClass, houstonClass, miamiClass, newOrleansClass, newYorkClass, virginiaClass, totalClass }, index) => {
                    const rowTotal = Antwerp + barcelona + HamburgBremen + Houston + miami + NewOrleans + NewYork + Virginia

                    return (
                      <tr key={index}>
                        <td data-testid="port-date-container">{dateFormatBasedOnUser(Date)}</td>
                        <td className={antwerpClass} data-testid="port-antwerp-container">
                          {formatArabicaReportValue(Antwerp)}
                        </td>
                        <td className={barcelonaClass} data-testid="port-barcelona-container">
                          {formatArabicaReportValue(barcelona)}
                        </td>
                        <td className={hamburgBremenClass} data-testid="port-hamburg-bremen-container">
                          {formatArabicaReportValue(HamburgBremen)}
                        </td>
                        <td className={houstonClass} data-testid="port-houston-container">
                          {formatArabicaReportValue(Houston)}
                        </td>
                        <td className={miamiClass} data-testid="port-miami-container">
                          {formatArabicaReportValue(miami)}
                        </td>
                        <td className={newOrleansClass} data-testid="port-new-orleans-container">
                          {formatArabicaReportValue(NewOrleans)}
                        </td>
                        <td className={newYorkClass} data-testid="port-new-york-container">
                          {formatArabicaReportValue(NewYork)}
                        </td>
                        <td className={virginiaClass} data-testid="port-virginia-container">
                          {formatArabicaReportValue(Virginia)}
                        </td>
                        <td className={totalClass} data-testid="port-row-total-container">
                          {formatArabicaReportValue(rowTotal)}
                        </td>
                        <td className={determineTextColor(ComparedPrevDay)} data-testid="port-compared-prev-day-container">
                          {formatArabicaReportValue(ComparedPrevDay)}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ))}
      </div>
    </div>
  )
}

export default PortStockReports
